import ui from "./userinfo.js";

export default [
  {
    name: "Charting",
    key: "menu_spreadchart",
    type: "menu",
    contents: [
      {
        name: "History Chart",
        key: "History_chart",
        link: "/history_chart",
      },
      {
        name: "Season Chart",
        key: "season_chart",
        link: "/season_chart",
      },
      {
        name: "Forward Curve",
        key: "forward_curve",
        link: "/forward_curve",
      },

      {
        name: "Formula Comment",
        key: "formula_comment",
        link: "/formula_comment",
      },
    ],
  },
  {
    name: "Crawller Maneger",
    key: "Crawller_Maneger",
    type: "link",
    link: "/Crawller_Maneger",
  },
  {
    name: "Data Config",
    key: "data_config",
    type: "link",
    link: "/data_config",
  },
  {
    name: "Weather File",
    key: "weather_file",
    type: "link",
    link: "/weather_file",
  },
  {
    name: "Users Management",
    key: "user",
    type: "link",
    link: "/user_mgt",
    is_admin: true,
  },
  {
    name: "Article Cate",
    key: "article_cate",
    type: "link",
    link: "/article_cate",
    is_admin: true,
  },
  {
    name: "Article List",
    key: "article_list",
    type: "link",
    link: "/article_list",
  },
  {
    name: "User Eidt",
    key: "user_edit",
    type: "link",
    link: "/user_edit",
  },
  {
    name: "Exit",
    key: "exit",
    type: "button",
    link: "/",
    click: (callback) => {
      ui.remove();
      callback();
    },
  },
];
